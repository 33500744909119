.yacht-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  .yacht-hero-container {
    width: 100%;
    max-width: 1268px;
    // background: url(../../assets/images/hero-image-yachts.jpeg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/hero-image-yachts.jpeg);
    background-position: center;
    background-size: cover;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 1px 3px 0px #1018281a;
    box-shadow: 0px 1px 2px 0px #101828;
    border-radius: 24px;
    padding-left: 16px;
    padding-right: 16px;

    h3 {
      font-size: 36px;
      line-height: 42px;
      margin-bottom: 24px;
      text-align: center;
      // max-width: 644px;
      color: #fff;
      @media screen and (min-width: 768px) {
        font-size: 50px; // mobile font size
        line-height: 72px;
        margin-bottom: 32px;
      }
    }
    .yacht-hero-search-form {
      width: 100%;
      max-width: 550px;
      .search-filed {
        font-size: 1rem;
        width: 100%;
        .MuiInputBase-root {
          background-color: #fff;
          // border: '1px solid #ced4da',
        }
      }
      display: flex;
      align-items: center;
    }
  }
  .plane-hero-container {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../assets/images/hero-image-planes.jpg);
    background-position: center;
    background-size: cover;
  }
}
.yacht-list {
  .yacht-list-container {
    padding: 20px;
    max-width: 1300px;
    margin: 0 auto;
  }

  .yacht-card {
    position: relative;
    cursor: pointer;
  }
  .yacht-card-price {
    background: #fff;
    border-radius: 5px;
    bottom: 132px;
    font-size: 0.875rem;
    font-weight: 600;
    left: 1rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
  }
  .yacht-card-price.featured {
    top: 0;
    height: fit-content;
    left: 0;
    background-color: #2baaea;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
    padding: 6px 16px;
  }
  .yacht-name {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
  }
  .yacht-details {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.875rem;
    line-height: 22px;
    color: #262632;
  }

  .yacht-rooms {
    height: 22px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 0.875rem;
    line-height: 22px;
    color: #262632;
  }
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  #nestyachts-sort select {
    padding: 5px;
    border-radius: 5px;
    color: #262632;
    border-color: #d0d5dd;
  }
}
.pagination-container,
.breadcrumbs-container {
  max-width: 1300px;
  padding: 0 16px;
  margin: 0 auto;
  margin-top: 20px;
  div {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    span,
    a {
      text-align: center;
      min-width: 20px;
      display: inline-block;
      padding: 5px;
      border: 1px solid #d0d5dd;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .MuiChip-root {
    background-color: unset;
    pointer-events: none;
  }
}

.yacht-page {
  .property-details-hero-section {
    .property-details-hero-container {
      .property-details-hero-images-wrapper {
        display: none;
        @media screen and (min-width: 900px) {
          display: flex;
          align-items: center;
          gap: 16px;
          margin-top: 24px;

          .main-image-wrapper {
            flex: 1 1;
            .main-image {
              border-radius: 12px;
              width: 410px;
              height: 396px;
              background-position: center center;
              background-size: cover;
              box-shadow: 0px 1px 2px 0px #1018280f;
              box-shadow: 0px 1px 3px 0px #1018281a;
            }
          }

          .grid-images-wrapper {
            position: relative;
            flex: 1 1;
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;
            .grid-image {
              width: 197px;
              height: 190px;
              border-radius: 12px;
              background-position: center center;
              background-size: cover;
              box-shadow: 0px 1px 2px 0px #1018280f;
              box-shadow: 0px 1px 3px 0px #1018281a;
            }
            .show-all-photos {
              position: absolute;
            }
          }
          .MuiButton-root:hover {
            background-color: #fff;
          }
        }
      }
      .property-details-hero-title {
        padding-bottom: 8px;
      }
      .location-paragraph {
        margin: 8px 0;
      }
    }
  }
  .contact-section {
    padding: 32px 16px;
    .contact-section-container {
      h3 {
        color: #101828;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 0 0 8px;
        text-align: left;
      }
      p {
        font-size: 1rem;
        color: #101828;
        font-weight: 400;
      }
      width: 100%;
      max-width: 836px;
      margin: 0 auto;
    }
  }
  .main-price {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 0;
    text-align: left;
  }
}
.yacht-overview-section {
  padding: 0 16px;
}
.yacht-overview {
  max-width: 836px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  .yacht-summary-details-section,
  .yacht-numbers-section {
    font-size: 0.875rem;
  }
  .yacht-numbers-section {
    width: 30%;
    display: flex;
    flex-direction: column;
    .yacht-number-detail {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      margin-bottom: 8px;
      font-size: 0.875rem;
      .fa {
        font-size: 1.25rem;
      }
    }
    // .sale-US-waters {
    //   display: none;
    // }
  }
  .yacht-summary-details-section {
    .yacht-detail {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      margin-bottom: 8px;
    }
    width: 60%;
    p {
      margin-bottom: 10px;
    }
  }
}
.yacht-detail-section {
  padding: 32px 16px;
  h3 {
    max-width: 836px;
    color: #101828;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 auto;
    margin-bottom: 16px;
    text-align: left;
  }
  .yacht-detail-container {
    max-width: 836px;
    margin: 0 auto;
    padding: 32px 0;
    position: relative;
    background-position: right center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 12px;
  }

  .yacht-detail-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    .yacht-detail-filter {
      font-size: 0.825rem;
      text-transform: uppercase;
      padding: 0.25rem 0.7rem;
      border-radius: 4px;
      color: #262632;
      cursor: pointer;
      margin-bottom: 0.5rem;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
    .yacht-detail-filters-active {
      background-color: #f5f5f5;
    }
  }
  .yacht-detail-data {
    background: white;
    min-height: 300px;
    max-width: 600px;
    padding: 2rem 1rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }
  .yacht-detail-data-content {
    .yacht-full-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;
      margin-bottom: 10px;
    }
    .details-title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    ul {
      margin: 0;
      li {
        margin-bottom: 5px;
      }
    }
  }
}

.yacht-full-specifications-section {
  .full-specifications-container {
    max-width: 836px;
    margin: 0 auto;
    padding: 32px 16px;
    h3 {
      max-width: 836px;
      color: #101828;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin: 0 auto;
      margin-bottom: 24px;
      text-align: center;
    }
    .full-specifications-key {
      text-transform: uppercase;
    }
    .full-specifications-value {
      ul {
        margin-top: 0;
        padding-left: 32px;
        li {
          list-style-type: disc;
          margin-bottom: 8px;
        }
      }
    }
  }
}

.yacht-price-filter {
  .location-range-value {
    gap: 8px;
    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      color: #101828;
    }
  }
  .location-filter {
    // width: 48%;
    .MuiSelect-outlined.MuiSelect-outlined {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    // .form-control {
    //   width: 100%;
    //   width: 190px;
    //   border: none;
    // }
  }
}

.yacht-home-filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  .btn-filter {
    &:hover {
      background-color: #fff;
    }
  }
}

.filter-popper {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 12px 0 #0000004d;
  .yacht-filter-fields-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  .yacht-filter-btn-wrapper {
    display: flex;
    gap: 12px;
  }

  .btn-delete {
    background: transparent;
    border: 0.5px solid #2baaea;
    color: #2baaea;
  }
  .btn-save {
    background: #2baaea;
    color: #fff;
  }

  .boat-type-form-control {
    padding: 16px;
    min-width: 200px;
    max-width: 400px;

    #boat-type-select {
      padding: 10px;
      min-width: 200px;
      max-width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  }
}

.yacht-filters-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 550px;
  .btn-filter {
    &:hover {
      background-color: #fff;
    }
  }
}
.plane-filters-wrapper {
  .btn-filter {
    padding: 6px 18px;
  }
}
.yacht-form-control-wrapper {
  max-width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 12px 0 #0000004d;
  .yacht-filter-form-fields-wrapper {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .yacht-filter-select-field {
      min-width: 280px;
      max-width: 300px;
      .MuiSelect-root {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  p {
    padding: 16px;
    margin: 0;
  }
  .yacht-filter-form-btn-wrapper {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-delete {
      background: transparent;
      border: 0.5px solid #2baaea;
      color: #2baaea;
    }
    .btn-save {
      background: #2baaea;
      color: #fff;
    }
  }
  .yacht-filter-form-btn-wrapper.plane-filter-form-btn-wrapper {
    padding: 8px 0 16px;
  }
  .range-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .range-header p {
    margin: 0;
  }
  .range-radio-group {
    display: flex;
    flex-direction: row;
    padding-right: 6px;
  }
  .range-radio-group .MuiFormControlLabel-root {
    margin-right: 10px; /* Spacing between radio buttons */
  }
}

.MuiFormControl-root.planes-form-control-wrapper {
  padding: 10px 15px 0;
  .planes-category-checkbox-group-label {
    align-items: end;
  }
  .planes-category-checkbox-group {
    padding-bottom: 0;
  }
  .planes-category-checkbox {
    padding: 5px;
  }
}

.yacht-skeleton-page {
  width: 100%;
  max-width: 836px;
  margin: 16px auto 0 auto;

  .hero-skeleton {
    margin-top: 12px;
    margin-bottom: 16px;
    .MuiSkeleton-root {
      border-radius: 12px;
    }
  }
}
