.yacht-list {
  .yacht-list-container {
    .plane-card-price {
      bottom: 110px;
    }
  }
}

.yacht-form-control-wrapper {
  .yacht-filter-form-fields-wrapper {
    .yacht-filter-fields.make-models-filter {
      min-width: 240px;
    }
  }
  .yacht-filter-form-fields-wrapper.plane-filter-form-fields-wrapper.column {
    flex-direction: column;
    small {
      // color: #888;
      padding-left: 2px;
    }
  }
}
